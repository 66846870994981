/**
 * Copyright(c) Live2D Inc. All rights reserved.
 *
 * Use of this source code is governed by the Live2D Open Software license
 * that can be found at https://www.live2d.com/eula/live2d-open-software-license-agreement_en.html.
 */

/**
 * @brief パラメータIDのデフォルト値を保持する定数<br>
 *         デフォルト値の仕様は以下のマニュアルに基づく<br>
 *         https://docs.live2d.com/cubism-editor-manual/standard-parametor-list/
 */
  // パーツID
export const HitAreaPrefix = 'HitArea';
export const HitAreaHead = 'Head';
export const HitAreaBody = 'Body';
export const PartsIdCore = 'Parts01Core';
export const PartsArmPrefix = 'Parts01Arm_';
export const PartsArmLPrefix = 'Parts01ArmL_';
export const PartsArmRPrefix = 'Parts01ArmR_';

// パラメータID
export const ParamAngleX = 'ParamAngleX';
export const ParamAngleY = 'ParamAngleY';
export const ParamAngleZ = 'ParamAngleZ';
export const ParamEyeLOpen = 'ParamEyeLOpen';
export const ParamEyeLSmile = 'ParamEyeLSmile';
export const ParamEyeROpen = 'ParamEyeROpen';
export const ParamEyeRSmile = 'ParamEyeRSmile';
export const ParamEyeBallX = 'ParamEyeBallX';
export const ParamEyeBallY = 'ParamEyeBallY';
export const ParamEyeBallForm = 'ParamEyeBallForm';
export const ParamBrowLY = 'ParamBrowLY';
export const ParamBrowRY = 'ParamBrowRY';
export const ParamBrowLX = 'ParamBrowLX';
export const ParamBrowRX = 'ParamBrowRX';
export const ParamBrowLAngle = 'ParamBrowLAngle';
export const ParamBrowRAngle = 'ParamBrowRAngle';
export const ParamBrowLForm = 'ParamBrowLForm';
export const ParamBrowRForm = 'ParamBrowRForm';
export const ParamMouthForm = 'ParamMouthForm';
export const ParamMouthOpenY = 'ParamMouthOpenY';
export const ParamCheek = 'ParamCheek';
export const ParamBodyAngleX = 'ParamBodyAngleX';
export const ParamBodyAngleY = 'ParamBodyAngleY';
export const ParamBodyAngleZ = 'ParamBodyAngleZ';
export const ParamBreath = 'ParamBreath';
export const ParamArmLA = 'ParamArmLA';
export const ParamArmRA = 'ParamArmRA';
export const ParamArmLB = 'ParamArmLB';
export const ParamArmRB = 'ParamArmRB';
export const ParamHandL = 'ParamHandL';
export const ParamHandR = 'ParamHandR';
export const ParamHairFront = 'ParamHairFront';
export const ParamHairSide = 'ParamHairSide';
export const ParamHairBack = 'ParamHairBack';
export const ParamHairFluffy = 'ParamHairFluffy';
export const ParamShoulderY = 'ParamShoulderY';
export const ParamBustX = 'ParamBustX';
export const ParamBustY = 'ParamBustY';
export const ParamBaseX = 'ParamBaseX';
export const ParamBaseY = 'ParamBaseY';
export const ParamNONE = 'NONE:';
